<template>
  <div>
    <modal name="tipoTicketEtapaEdit" height="auto" width="900" :scrollable="true" :clickToClose="false">
      <CloseModal :close="close"/>
      <div class="modalbox">
        <div class="modalbox-content">
          <CTabs variant="tabs" class="nav-tabs-boxed">
            <CTab :title="getTitleModal(item) + descSingular">
              <div class="xrow">
                <div class="col-1-2">
                  <div class="form-group">
                    <label for="">Descrição</label>
                    <input class="form-control" v-model="item.desc"/>
                    <div class="message">{{ validation.firstError('item.desc') }}</div>
                  </div>
                </div>
                <div class="col-1-2">
                  <div class="form-group">
                    <label for="">Tipo Ticket</label>
                    <select class="form-control" v-model="item.tipo_ticket_id">
                      <option v-for="tpTicket in tipoTicket" :value="tpTicket.id" :key="tpTicket.id">
                        {{ tpTicket.desc }}
                      </option>
                    </select>
                    <div class="message">{{ validation.firstError('item.tipo_ticket_id') }}</div>
                  </div>
                </div>
              </div>
              <div class="xrow">
                <div class="col-1-1">
                  <div class="form-group">
                    <label for="">Observação</label>
                    <textarea class="form-control" v-model="item.obs" />
                    <div class="message">{{ validation.firstError('item.obs') }}</div>
                  </div>
                </div>
              </div>
              <div class="xrow">
                <div class="col-1-3">
                  <div class="form-group">
                    <label>SLA máximo</label>
                    <input
                      class="form-control"
                      autocomplete="chrome-off"
                      v-model="item.sla_maximo"
                      type="number"
                    />
                    <div class="message">{{ validation.firstError('item.sla_maximo') }}</div>
                  </div>
                </div>
                <div class="col-1-3">
                  <div class="form-group">
                    <label for=""> Permite finalizar </label>
                    <select class="form-control" v-model="item.permite_finalizar">
                      <option value="1">Sim</option>
                      <option value="0">Não</option>
                    </select>
                    <div class="message">{{ validation.firstError('item.permite_finalizar') }}</div>
                  </div>
                </div>
                <div class="col-1-3">
                  <div class="form-group">
                    <label for=""> Permite retroceder </label>
                    <select class="form-control" v-model="item.permite_retroceder">
                      <option value="1">Sim</option>
                      <option value="0">Não</option>
                    </select>
                    <div class="message">{{ validation.firstError('item.permite_retroceder') }}</div>
                  </div>
                </div>                
              </div>
              <div class="xrow">
                <div class="col-1-3">
                  <div class="form-group">
                    <label>Ordem</label>
                    <input
                      class="form-control"
                      autocomplete="chrome-off"
                      v-model="item.ordem"
                      type="number"
                    />
                    <div class="message">{{ validation.firstError('item.ordem') }}</div>
                  </div>
                </div>
                <div class="col-1-3">
                  <div class="form-group">
                    <label for=""> Aguarda cliente </label>
                    <select class="form-control" v-model="item.aguarda_cliente">
                      <option value="1">Sim</option>
                      <option value="0">Não</option>
                    </select>
                    <div class="message">{{ validation.firstError('item.aguarda_cliente') }}</div>
                  </div>
                </div>
                <div class="col-1-3">
                  <div class="form-group">
                    <label for="">Requerimento</label>
                    <select class="form-control" v-model="item.requerimento">
                      <option value="">-</option>
                      <option v-for="(desc, key) in tipoTicketEtapaRequerimento" :value="key" :key="key">
                        {{ desc }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="xrow">
                <div class="col-1-3">
                  <div class="form-group">
                    <label for="">Requerimento ID</label>
                    <select class="form-control" v-model="item.requerimento_id">
                      <option value="">-</option>
                      <option v-for="(desc, key) in tipoTicketEtapaRequerimentoId" :value="key" :key="key">
                        {{ desc }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-1-3">
                  <div class="form-group">
                    <label for=""> Permite avançar </label>
                    <select class="form-control" v-model="item.permite_avancar">
                      <option value="1">Sim</option>
                      <option value="0">Não</option>
                    </select>
                    <div class="message">{{ validation.firstError('item.permite_avancar') }}</div>
                  </div>
                </div>
                <div class="col-1-3">
                  <div class="form-group">
                    <label for=""> Final </label>
                    <select class="form-control" v-model="item.final">
                      <option value="1">Sim</option>
                      <option value="0">Não</option>
                    </select>
                    <div class="message">{{ validation.firstError('item.final') }}</div>
                  </div>
                </div>
              </div>
              <div class="xrow">
                <div class="col-1-3">
                  <div class="form-group">
                    <label for="">Setor</label>
                    <select class="form-control" v-model="item.setor_id">
                      <option value="">-</option>
                      <option v-for="setor in setores" :value="setor.id" :key="setor.id">
                        {{ setor.desc }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-1-3">
                  <div class="form-group">
                    <label for=""> Permite custos </label>
                    <select class="form-control" v-model="item.permite_custos">
                      <option value="1">Sim</option>
                      <option value="0">Não</option>
                    </select>
                    <div class="message">{{ validation.firstError('item.permite_custos') }}</div>
                  </div>
                </div>
              </div>
              <div class="xrow edit-buttons">
                <button :class="{ 'button button-success': true, 'button-disabled': validation.hasError() }"
                        v-on:click="handleSubmit">
                  Salvar
                </button>
                &nbsp;
                <button class="button" v-on:click="close">Fechar</button>
              </div>
            </CTab>
          </CTabs>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import SimpleVueValidation from 'simple-vue-validator';
import CloseModal from '../../../../components/CloseModal';

const Validator = SimpleVueValidation.Validator.create({
  templates: {
    required: "*obrigatório"
  },
});

export default {
  name: 'tipoTicketEtapaEdit',
  props: ['item', 'update', 'close', 'descSingular', 'tipoTicket', 'tipoTicketEtapaRequerimento', 'tipoTicketEtapaRequerimentoId', 'setores'],
  components: { CloseModal },
  validators: {
    'item.tipo_ticket_id'     : (value) => Validator.value(value).required(),
    'item.desc'               : (value) => Validator.value(value).required(),
    'item.permite_finalizar'  : (value) => Validator.value(value).required(),
    'item.permite_retroceder' : (value) => Validator.value(value).required(),
    'item.ordem'              : (value) => Validator.value(value).required(),
    'item.aguarda_cliente'    : (value) => Validator.value(value).required(),
    'item.permite_avancar'    : (value) => Validator.value(value).required(),
    'item.final'              : (value) => Validator.value(value).required(),
    'item.permite_custos'     : (value) => Validator.value(value).required(),
  },
  methods: {
    handleSubmit() {
      this.$validate().then((success) => {
        if (success) {
          this.update();
        }
      });
    },
    getTitleModal(item) {
      if (item.id) {
        return 'Editando ';
      }
      return 'Criando ';
    }
  }
};
</script>
