<template>
  <div>
    <modal name="ticketAcaoObjetoEdit" height="auto" width="900" :scrollable="true" :clickToClose="false">
      <CloseModal :close="close"/>
      <div class="modalbox">
        <div class="modalbox-content">
          <CTabs variant="tabs" class="nav-tabs-boxed">
            <CTab :title="getTitleModal(item) + descricaoSingularAcao('ADMIN_SAC', 'SAC_TICKET_ACAO_OBJETO')">
              <div class="xrow">
                <div class="col-1-2">
                  <div class="form-group">
                    <label for="">Nome</label>
                    <input class="form-control" v-model="item.nome"/>
                    <div class="message">{{ validation.firstError('item.nome') }}</div>
                  </div>
                </div>
                <div class="col-1-2">
                  <div class="form-group">
                    <label for="">Evento</label>
                    <select class="form-control" v-model="item.evento">
                      <option v-for="(desc, key) in ticketAcaoObjetoEvento" :value="key" :key="key">
                        {{ desc }}
                      </option>
                    </select>
                    <div class="message">{{ validation.firstError('item.evento') }}</div>
                  </div>
                </div>
              </div>
              <div class="xrow">
                <div class="col-1-3">
                  <div class="form-group">
                    <label>Ordem</label>
                    <input
                      class="form-control"
                      autocomplete="chrome-off"
                      v-model="item.ordem"
                      type="number"
                    />
                    <div class="message">{{ validation.firstError('item.ordem') }}</div>
                  </div>
                </div>
                <div class="col-1-3">
                  <div class="form-group">
                    <label for=""> Ativo </label>
                    <select class="form-control" v-model="item.ativo">
                      <option value="1">Sim</option>
                      <option value="0">Não</option>
                    </select>
                    <div class="message">{{ validation.firstError('item.ativo') }}</div>
                  </div>
                </div>
                <div class="col-1-3">
                  <div class="form-group">
                    <label for=""> Validar todas as regras </label>
                    <select class="form-control" v-model="item.validar_todas_regras">
                      <option value="1">Sim</option>
                      <option value="0">Não</option>
                    </select>
                    <div class="message">{{ validation.firstError('item.validar_todas_regras') }}</div>
                  </div>
                </div>                
              </div>              
            </CTab>
            <CTab :title="getTitleModal(item) + descricaoSingularAcao('ADMIN_SAC', 'SAC_TICKET_ACAO_REGRA')">
              <TicketAcaoRegraEdit
                :item="item"
                :update="update"
                :close="close"
                :ticketAcaoObjetoEvento="ticketAcaoObjetoEvento"
                :ticketAcaoRegraColuna="ticketAcaoRegraColuna"
                :ticketAcaoRegraCondicao="ticketAcaoRegraCondicao"
              ></TicketAcaoRegraEdit>
            </CTab>
            <CTab :title="getTitleModal(item) + descricaoSingularAcao('ADMIN_SAC', 'SAC_TICKET_ACAO_EXECUCAO')">
              <TicketAcaoExecucaoEdit
                :item="item"
                :update="update"
                :close="close"
                :ticketAcaoExecucaoAcao="ticketAcaoExecucaoAcao"
                :ticketAcaoExecucaoTipo="ticketAcaoExecucaoTipo"
              ></TicketAcaoExecucaoEdit>
            </CTab>
          </CTabs>
          <div class="xrow edit-buttons">
            <button :class="{ 'button button-success': true, 'button-disabled': validation.hasError() }"
                    v-on:click="handleSubmit">
              Salvar
            </button>
            &nbsp;
            <button class="button" v-on:click="close">Fechar</button>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import SimpleVueValidation        from 'simple-vue-validator';
import CloseModal                 from '../../../../components/CloseModal';
import { descricaoSingularAcao }  from '@/helpers/cargas';
import TicketAcaoRegraEdit        from './TicketAcaoRegra/TicketAcaoRegraEdit.vue';
import TicketAcaoExecucaoEdit     from './TicketAcaoExecucao/TicketAcaoExecucaoEdit.vue';

const Validator = SimpleVueValidation.Validator.create({
  templates: {
    required: "*obrigatório"
  },
});

export default {
  name: 'ticketAcaoObjetoEdit',
  props: ['item', 'update', 'close', 'ticketAcaoObjetoEvento', 'ticketAcaoRegraColuna', 'ticketAcaoRegraCondicao', 'ticketAcaoExecucaoAcao', 'ticketAcaoExecucaoTipo'],
  components: { CloseModal, TicketAcaoRegraEdit, TicketAcaoExecucaoEdit },
  validators: {
    'item.nome'                : (value) => Validator.value(value).required(),
    'item.evento'              : (value) => Validator.value(value).required(),
    'item.ordem'               : (value) => Validator.value(value).required(),
    'item.ativo'               : (value) => Validator.value(value).required(),
    'item.validar_todas_regras': (value) => Validator.value(value).required(),
  },
  methods: {
    descricaoSingularAcao,
    handleSubmit() {
      this.$validate().then((success) => {
        if (success) {
          this.update();
        }
      });
    },
    getTitleModal(item) {
      if (item.id) {
        return 'Editando ';
      }
      return 'Criando ';
    }
  }
};
</script>
