<template>
  <div>
    <div class="xrow">
      <div class="col-1-1 actions edit-buttons">
          <button type="button" class="button button-primary" v-on:click="addAcao">
              Adicionar Ação
          </button>
      </div>
      <div class="col-1-1">
        <div class="form-group">
            <label>Ações</label>
        </div>

        <div class="acao caption" v-if="item.acoes.length === 0">
            Nenhuma ação vinculada ...
        </div>

        <div v-for="acao in item.acoes">
          <div class="acao">
            <a class="delete-acao" v-on:click="deleteAcao(acao)">
                &times;
            </a>
            <div>
              <div class="col-1-3">
                <div class="form-group">
                  <label>Tipo Execução</label>
                  <select class="form-control" v-model="acao.tipo_execucao">
                    <option v-for="tipo in ticketAcaoExecucaoTipo" :value="tipo" :key="tipo"> {{ tipo }} </option>
                  </select>
                </div>
              </div>
              <div class="col-2-3">
                <div class="form-group">
                  <label>Ação</label>
                  <ModelListSelect
                    v-if="acao.tipo_execucao == 'JOB'"
                    :list="ticketAcaoExecucaoAcao"
                    v-model="acao.acao.class_method_or_sql"
                    option-value="class_method_or_sql"
                    option-text="class_method_or_sql"
                    placeholder="Pesquisar">
                  </ModelListSelect>
                  <codemirror
                    v-if="acao.tipo_execucao != 'JOB'" 
                    v-model="acao.acao.class_method_or_sql"
                    :options="code_options">
                  </codemirror>
                </div>
              </div>
              <div v-if="acao.tipo_execucao == 'JOB'">
                <div class="col-1-3"                
                  v-for="(param, key) in getParamsClassMethod(acao.acao.class_method_or_sql)"
                  :key="key"
                >
                  <div class="form-group">
                    Tipo: 
                    <label v-if="param.class"> {{ param.class }} </label>
                    <label style="color:#eead2d" v-else> Texto </label>
                    
                    Nome: 
                    <label> {{ param.name }} </label>
                    
                    Padrão:
                    <label v-if="param.value"> {{ param.value }} </label>
                    <label style="color:#eead2d" v-else> Texto </label>                    
                    
                    <input class="form-control" v-model="acao.acao.params[key]" />

                    <label class="message" style="color:red" v-if="!param.is_optional"> Obrigatório </label>
                    <label class="message" style="color:green" v-else> Opcional </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="xrow">
      <div class="col-1-1">
        <h4>Tipo Execução</h4>
        <p>
          <small>
            Tipo de execução disponibiliza as opções JOB e SQL, para JOB, ações pré-definidas com base no ERP ou Gerais serão dispobilizadas para seleção.
            No caso de SQL, uma caixa para colocagem do código armazenará os comandos.
            <strong>
              <pre>
                Exemplo JOB: App\Services\Ticket\Actions\GeraDevolucaoTicket@run
                Exemplo SQL: UPDATE table SET colum = 1 WHERE id = 1
              </pre>
            </strong>
          </small>
        </p>

        <h4>Ação</h4>
        <p>
          <small>
            Ação armazena um tipo de ação pré-definida ou uma query de banco de dados.
          </small>
        </p>

        <h4>Parâmetros</h4>
        <p>
          <small>
            Os parâmetros estarão disponíveis apenas para o tipo de execução JOB.
            São a representação de parâmetros que deverão ser passados para o método de implementação da ação.
            <strong>
              <pre>Exemplo: 
                App\Services\Ticket\Actions\GeraDevolucaoTicket@run
                
                public function run(Ticket $ticket, Colaborador $sender)</pre>
            </strong>
          </small>
        </p>

        <h4>Tipo</h4>
        <p>
          <small>
            Tipo informa qual o tipo exato do dado esperado no parâmetro do método.
            <strong>
              <pre>Exemplo: App\Models\Ticket = Objeto do ticket em questão instânciado pelo valor :ticket </pre>
            </strong>
          </small>
        </p>

        <h4>Padrão</h4>
        <p>
          <small>
            É o valor padrão pré-estabelecido para o parâmetro, é recomendado a utilização.
          </small>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { ModelListSelect } from 'vue-search-select'
import 'vue-search-select/dist/VueSearchSelect.css'

import { codemirror }     from 'vue-codemirror'

// theme css
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/monokai.css'

// language js
import 'codemirror/addon/selection/active-line.js'
import 'codemirror/mode/sql/sql.js'
import 'codemirror/addon/display/autorefresh.js'

// autoCloseTags
import 'codemirror/addon/edit/closetag.js'

import _filter from 'lodash/filter'

const novaAcao = (acao_objeto_id) => {
  return {
    id: null,
    acao_objeto_id: acao_objeto_id,
    tipo_execucao: null,
    acao: {
      class_method_or_sql: null,
      params: []
    },
  }
}

export default {
  name: 'acoes',
  components: {
    codemirror, ModelListSelect
  },
  props: ['item', 'ticketAcaoExecucaoAcao', 'ticketAcaoExecucaoTipo'],
  data() {
    return {
      code_options: {
        tabSize: 4,
        styleActiveLine: true,
        lineNumbers: true,
        line: true,
        mode: 'text/x-sql',
        autoRefresh:true,
        lineWrapping: true,
        theme: 'monokai',
        showCursorWhenSelecting: true,
        highlightSelectionMatches: {
          showToken: /\w/,
          annotateScrollbar: true
        },
      },
    }
  },
  methods: {
    addAcao() {
      let acao = novaAcao( this.item.id )
      this.item.acoes.push(acao);
    },
    deleteAcao(acao) {
      this.item.acoes = _filter(this.item.acoes, (p) => p !== acao);
    },
    getParamsClassMethod(classMethod) {
      if (!classMethod) {
        return undefined;
      }
      return _filter(this.ticketAcaoExecucaoAcao, (p) => p.class_method_or_sql == classMethod)[0].params;
    }
  }
};

</script>
<style scoped>
    .acao {
        border: 2px solid #ccc;
        margin-bottom: 16px;
        position: relative;        
        min-height: 320px;
    }

    .acao .delete-acao {
        border: 2px solid #ccc;
        background-color: #aaa;
        color: #fff;
        display: block;
        width: 30px;
        height: 30px;
        font-weight: bold;
        font-size: 30px;
        text-align: center;
        border-radius: 50px;
        position: absolute;
        top: -15px;
        right: -15px;
        line-height: 0.85;
        cursor: pointer;
    }
</style>

<style>
    .acao .CodeMirror {
        margin-top: 16px;
        font-size: 10px;
        height: 150px;
    }
</style>