import { get } from "./apiRequest";
import {uuid} from "vue-uuid";

function loadCargas(token, onSuccess, onError) {
  get('/admin/carga')
    .then((carga) => {
      carga._hash = uuid.v4();
      localStorage.setItem('app', JSON.stringify(carga));

      if (onSuccess) {
        onSuccess(carga);
      }
    })
    .catch((err) => {
      localStorage.removeItem('token');

      if (onError) {
        onError(err);
      }
    });
}

function loadAuthorizationAction(modulo, acao) {
  const { modulos } = JSON.parse(localStorage.getItem('app'));
  const { moduloAcoes } = modulos.find(
    (m) => m.nome === modulo
  );
  const { autorizado } = moduloAcoes.find(
    (m) => m.nome === acao
  );

  return autorizado;
}

function descricaoPluralAcao(modulo, acao) {
  const { modulos } = JSON.parse(localStorage.getItem('app'));
  const { moduloAcoes } = modulos.find(
    (m) => m.nome === modulo
  );
  const { descPlural } = moduloAcoes.find(
    (m) => m.nome === acao
  );

  return descPlural;
}

function descricaoSingularAcao(modulo, acao) {
  const { modulos } = JSON.parse(localStorage.getItem('app'));
  const { moduloAcoes } = modulos.find(
    (m) => m.nome === modulo
  );
  const { descSingular } = moduloAcoes.find(
    (m) => m.nome === acao
  );

  return descSingular;
}

function getDescricaoAcessoSistema(acesso) {
  if (acesso == 'acessab2b' || acesso == 'acessob2b') {
    var chave = 'B2B';
  }

  if (acesso == 'acessacallcenter' || acesso == 'acessocallcenter') {
    var chave = 'CALLCENTER';
  }

  if (acesso == 'acessafv' || acesso == 'acessofv') {
    var chave = 'FORCA_VENDAS';
  }

  const { sistemas } = JSON.parse(localStorage.getItem('app'));
  const { descricao } = sistemas.find(
    (m) => m.chave === chave
  ) || '';

  return 'Disponível ' + (descricao || chave);
}

function descricaoPluralModulo(modulo) {
  const { modulos } = JSON.parse(localStorage.getItem('app'));
  const { descPlural } = modulos.find(
    (m) => m.nome === modulo
  );
  
  return descPlural;
}

function descricaoSingularModulo(modulo) {
  const { modulos } = JSON.parse(localStorage.getItem('app'));
  const { descSingular } = modulos.find(
    (m) => m.nome === modulo
  );
  
  return descSingular;
}

export {
  loadCargas,
  loadAuthorizationAction,
  descricaoPluralAcao,
  descricaoSingularAcao,
  getDescricaoAcessoSistema,
  descricaoPluralModulo,
  descricaoSingularModulo
}