<template>
  <div>
    <div class="xrow">
      <div class="col-1-1 actions edit-buttons">
          <button type="button" class="button button-primary" v-on:click="addRegra">
              Adicionar Regra
          </button>
      </div>
      <div class="col-1-1">
        <div class="form-group">
            <label>Regras</label>
        </div>

        <div class="regra caption" v-if="item.regras.length === 0">
            Nenhuma regra vinculada ...
        </div>

        <div v-for="regra in item.regras">
          <div class="regra">
            <a class="delete-regra" v-on:click="deleteRegra(regra)">
                &times;
            </a>
            <div class="regra-content">
              <div class="col-1-3">
                <div class="form-group">
                  <label>Tipo</label>
                  <select class="form-control" v-model="regra.tipo">
                    <option value="default">Padrão</option>
                    <option value="sql">SQL</option>
                  </select>
                </div>
              </div>
              <div class="col-1-3">
                <div class="form-group">
                  <label for="">Coluna</label>
                  <ModelListSelect :list="ticketAcaoRegraColuna"
                     v-model="regra.coluna"
                     option-value="value"
                     option-text="value"                     
                     placeholder="Pesquisar">
                  </ModelListSelect>
                </div>
              </div>
              <div class="col-1-3">
                <div class="form-group">
                  <label>Condição</label>
                  <select class="form-control" v-model="regra.condicao">
                    <option v-for="(desc, key) in ticketAcaoRegraCondicao" :value="key" :key="key">
                      {{ desc }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-2-3">
                <div class="form-group">
                  <label>Valor</label>
                  <codemirror
                    v-model="regra.valor"
                    :options="code_options">
                  </codemirror>
                </div>
              </div>
              <div class="col-1-3">
                <div class="form-group">
                  <div class="form-group">
                    <label>Banco</label>
                    <select v-model="regra.banco" class="form-control" :disabled="regra.tipo != 'sql'">
                      <option value="local">Local</option>
                      <option value="erp">ERP</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="xrow">
      <div class="col-1-1">
        <h4>Tipo</h4>
        <p>
          <small>
            Para utilização da coluna tipo na seleção padrão, o valor inserido deve ser um texto de comparação com a coluna selecionada.
            <strong>
              <pre>Exemplo: GARANTIA</pre>
            </strong>
          </small>
        </p>
        <p>
          <small>
            Para utilização da coluna tipo na seleção SQL, a consulta deve retornar apenas uma coluna de comparação utilizando o nome "valor"("as valor").
            <strong>
              <pre>Exemplo: SELECT colaborador_id as valor FROM user WHERE setor_id = 1</pre>
            </strong>
          </small>
        </p>

        <h4>Coluna</h4>
        <p>
          <small>
            Os dados disponíveis na seleção do campo coluna representam campos da tabela "ticket" ou campos de tabelas relacionadas com o Ticket em questão.
            <strong>
              <pre>Exemplo: origem ou etapaAtual.permite_avancar</pre>
            </strong>
          </small>
        </p>

        <h4>Condição</h4>
        <p>
          <small>
            Condição é a comparação utilizada entre o valor do campo "Coluna" e o valor do campo "Valor" para validar se a regra é válida.
            <strong>
              <pre>Exemplo: etapaAtual.permite_avancar = true</pre>
            </strong>
          </small>
        </p>

        <h4>Valor</h4>
        <p>
          <small>
            Valor é um texto de comparação ou query que será utilizado para comparação entre a coluna.
          </small>
        </p>

        <h4>Banco</h4>
        <p>
          <small>
            A coluna banco define em qual banco de dados a query será exucutada, caso o tipo selecionado seja SQL.
          </small>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { ModelListSelect } from 'vue-search-select'
import 'vue-search-select/dist/VueSearchSelect.css'

import { codemirror }     from 'vue-codemirror'

// theme css
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/monokai.css'

// language js
import 'codemirror/addon/selection/active-line.js'
import 'codemirror/mode/sql/sql.js'
import 'codemirror/addon/display/autorefresh.js'

// autoCloseTags
import 'codemirror/addon/edit/closetag.js'

import _filter from 'lodash/filter'

const novaRegra = (acao_objeto_id) => {
  return {
    acao_objeto_id: acao_objeto_id,
    banco: null,
    coluna: null,
    condicao: null,
    id: null,
    tipo: null,
    valor: null
  }
}

export default {
  name: 'regras',
  components: {
    codemirror, ModelListSelect
  },
  props: ['item', 'ticketAcaoRegraColuna', 'ticketAcaoRegraCondicao'],
  data() {
    return {
      code_options: {
        tabSize: 4,
        styleActiveLine: true,
        lineNumbers: true,
        line: true,
        mode: 'text/x-sql',
        autoRefresh:true,
        lineWrapping: true,
        theme: 'monokai',
        showCursorWhenSelecting: true,
        highlightSelectionMatches: {
          showToken: /\w/,
          annotateScrollbar: true
        },
      },
    }
  },
  methods: {
    addRegra() {
      let regra = novaRegra( this.item.id )
      this.item.regras.push(regra);
    },
    deleteRegra(regra) {
      this.item.regras = _filter(this.item.regras, (p) => p !== regra);
    },
  }
};

</script>
<style scoped>
    .regra {
        border: 2px solid #ccc;
        margin-bottom: 16px;
        position: relative;
    }

    .regra-content {
        overflow: hidden;
    }

    .regra .delete-regra {
        border: 2px solid #ccc;
        background-color: #aaa;
        color: #fff;
        display: block;
        width: 30px;
        height: 30px;
        font-weight: bold;
        font-size: 30px;
        text-align: center;
        border-radius: 50px;
        position: absolute;
        top: -15px;
        right: -15px;
        line-height: 0.85;
        cursor: pointer;
    }
</style>

<style>
    .regra .CodeMirror {
        margin-top: 16px;
        font-size: 10px;
        height: 150px;
    }
</style>